import "@/styles/globals.css";
import "react-toastify/dist/ReactToastify.css";
import Head from "next/head";
import Script from "next/script";
import LoadingBar from "react-top-loading-bar";

import { CurrentUserProvider } from "@/context/UserContext";
import { FC, Fragment, useEffect, useRef } from "react";
import { appWithTranslation } from "next-i18next";
import { ToastContainer } from "react-toastify";

import type { NextComponentType } from "next";
import type { AppProps } from "next/app";

import { useRouter } from "next/router";
import { SWRConfig } from "swr";
import swrFetcher from "@/utils/swrFetcher";

const GA_MEASUREMENT_ID = process.env.NEXT_PUBLIC_GOOGLE_MEASUREMENT_ID;

export type NextPageWithLayout = NextComponentType & {
  getLayout?: FC;
};

export type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

function MyApp({ Component, pageProps }: AppPropsWithLayout) {
  // Use the layout defined at the page level, if available
  const Layout: NextPageWithLayout | typeof Fragment = Component.getLayout
    ? Component.getLayout
    : Fragment;

  // react-top-loading-bar
  const ref = useRef<any>(null);
  const router = useRouter();

  useEffect(() => {
    router.events.on("routeChangeStart", () => ref.current?.continuousStart());
    router.events.on("routeChangeComplete", () => ref.current?.complete());
    router.events.on("routeChangeError", () => ref.current?.complete());
  }, [router.events]);

  return (
    <Fragment>
      <Head>
        <title>LeagueConnect - League of Legends Community hub</title>
      </Head>
      <Script
        src={`https://www.googletagmanager.com/gtag/js?id=${GA_MEASUREMENT_ID}`}
        strategy="afterInteractive"
      />
      <Script id="google-analytics" strategy="afterInteractive">
        {`
        window.dataLayer = window.dataLayer || [];
        function gtag(){window.dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', '${GA_MEASUREMENT_ID}');
      `}
      </Script>
      <CurrentUserProvider>
        <LoadingBar height={3} color="#2980B9" ref={ref} />
        <SWRConfig value={{ fetcher: swrFetcher }}>
          <Layout>
            <Component {...pageProps} />
          </Layout>
        </SWRConfig>
        <ToastContainer containerId="toast-container" />
      </CurrentUserProvider>
    </Fragment>
  );
}

export default appWithTranslation(MyApp);
