import axios from "./axios";
import router from "next/router";
import jwt_decode from "jwt-decode";
import { UserToken } from "@/models/auth";
import { toast } from "react-toastify";
import { TOAST_ERROR } from "@/styles/toast";
import { USER_DASHBOARD } from "@/constants/routes";
import { AxiosAuthRefreshRequestConfig } from "axios-auth-refresh";

const ACCESS_TOKEN = "leagueconnect_access_token";
const REFRESH_TOKEN = "leagueconnect_refresh_token";
const TOKEN_TIMESTAMP = "leagueconnect_token_timestamp";

// Get tokens from localstorage
export function getLocalAccessToken() {
  if (typeof window !== "undefined") {
    return window.localStorage.getItem(ACCESS_TOKEN);
  }
}

function getLocalRefreshToken() {
  if (typeof window !== "undefined") {
    return window.localStorage.getItem(REFRESH_TOKEN);
  }
}

export function getLocalTokenTimestamp() {
  if (typeof window !== "undefined") {
    return window.localStorage.getItem(TOKEN_TIMESTAMP);
  }
}

// Set tokens in localstorage
function setLocalAccessToken(token: string) {
  if (typeof window !== "undefined") {
    window.localStorage.setItem(ACCESS_TOKEN, token);
  }
}

function setLocalRefreshToken(token: string) {
  if (typeof window !== "undefined") {
    window.localStorage.setItem(REFRESH_TOKEN, token);
  }
}

function setLocalTokenTimestamp(timestamp: string) {
  if (typeof window !== "undefined") {
    window.localStorage.setItem(TOKEN_TIMESTAMP, timestamp);
  }
}

export async function refreshAccessToken(failedRequest: any): Promise<void> {
  const payload = { refresh_token: getLocalRefreshToken() };

  return await axios.put(`/api/token/refresh`, payload).then((res) => {
    const { token, refresh_token } = res.data;

    setLocalAccessToken(token);
    setLocalRefreshToken(refresh_token);

    // Retry failed API call
    failedRequest.response.config.headers["Authorization"] = "Bearer " + token;

    return Promise.resolve();
  });
}

export async function login(credentials: { email: string; password: string }) {
  const axiosRefreshConfig = {
    skipAuthRefresh: true,
  } as AxiosAuthRefreshRequestConfig;

  // Fetch user's access and bearer tokens
  await axios
    .post(`/auth/login`, credentials, axiosRefreshConfig)
    .then((res) => {
      const token = jwt_decode(res.data.token) as UserToken;

      // Set tokens in localstorage
      setLocalAccessToken(res.data.token);
      setLocalTokenTimestamp(token.exp.toString());
      setLocalRefreshToken(res.data.refresh_token);

      router.push(USER_DASHBOARD);
    })
    .catch((error) => {
      toast.error(error.response.data.message, TOAST_ERROR);
    });
}

export const logout = () => {
  if (typeof window !== "undefined") {
    window.localStorage.removeItem(ACCESS_TOKEN);
    window.localStorage.removeItem(REFRESH_TOKEN);
    window.localStorage.removeItem(TOKEN_TIMESTAMP);
    window.location.reload();
  }
};

export const token = getLocalAccessToken();
