import axios from "axios";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import { refreshAccessToken } from "./auth";

axios.defaults.baseURL = process.env.NEXT_PUBLIC_API_URL;
axios.defaults.headers.post["Content-Type"] = "application/json";

// Set bearer token in all outgoing requests
axios.interceptors.request.use(
  async (config) => {
    const token = window.localStorage.getItem("leagueconnect_access_token");
    const lang = document.documentElement.lang;

    config.headers = token
      ? { Authorization: `Bearer ${token}`, "Accept-Language": lang }
      : { "Accept-Language": lang };

    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

// Fetch new refresh token if a 401 API response is intercepted
createAuthRefreshInterceptor(axios, refreshAccessToken, { statusCodes: [401] });

export default axios;
