import React, { createContext, useEffect, useState } from "react";
import jwt_decode from "jwt-decode";
import { User, UserToken } from "@/models/auth";
import { getLocalAccessToken } from "@/utils/auth";

interface CurrentUserProviderProps {
  children: React.ReactNode;
}

interface UserContext {
  currentUser: User;
  setCurrentUser: React.Dispatch<React.SetStateAction<User>>;
}

export const CurrentUserContext = createContext({} as UserContext);

export const CurrentUserProvider = ({ children }: CurrentUserProviderProps) => {
  const [currentUser, setCurrentUser] = useState({} as User);
  const access_token = getLocalAccessToken();

  useEffect(() => {
    if (!access_token) return;

    const user = jwt_decode(access_token) as UserToken;

    setCurrentUser(user);
  }, [access_token]);

  return (
    <CurrentUserContext.Provider value={{ currentUser, setCurrentUser }}>
      {children}
    </CurrentUserContext.Provider>
  );
};
