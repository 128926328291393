const TOAST_BASE = {
  theme: "light",
  position: "top-right",
  draggable: false,
  closeOnClick: true,
};

export const TOAST_SUCCESS = {
  ...TOAST_BASE,
  autoClose: 5000,
  hideProgressBar: false,
} as {};

export const TOAST_ERROR = {
  ...TOAST_BASE,
  autoClose: false,
  hideProgressBar: true,
} as {};
